.description {
  display: flex;
  align-items:center; 
  justify-content: center;
  gap: 10px;
}
.description::before {
  content: "";
  display:block;
  background-color: #546E7A;
  width: 30px;
  height: 1px;
}
.description::after {
  content: "";
  display:block;
  background-color: #546E7A;
  width: 30px;
  height: 1px;
}